import { css } from '@emotion/react';
import { useThemeUI } from 'theme-ui';
import { Heading, Text as RebassText } from 'rebass';
import React from 'react';

const commonVariants = {
  bold: css`
    font-weight: 700;
  `,
  semiBold: css`
    font-weight: 600;
  `,
  medium: css`
    font-weight: 500;
  `,
  light: css`
    font-weight: 400;
  `,
  italic: css`
    font-weight: 400;
    font-style: italic;
  `,
  semiBoldItalic: css`
    font-weight: 600;
    font-style: italic;
  `,
  underline: css`
    font-weight: 600;
    text-decoration-line: underline;
  `,
};

const commonStyles = props => css`
  ${props.inline ? 'display: inline;' : ''}
  &:hover {
    ${props => (props.hoverColor ? `color: ${props.hoverColor};` : '')}
  }
  em {
    font-style: italic;
  }
`;

export const HeadingBase = ({
  type = 'h1',
  variant = 'semiBold',
  ...props
}) => {
  const fontSizes = {
    h1: { xs: 11, tabletP: 13 },
    h2: {
      xs: 9,
      tabletP: 12,
    },
    h3: {
      xs: 5,
      tabletP: 7,
    },
  };

  const colors = {
    h1: 'primary',
    h2: 'heading',
    h3: 'primary',
  };

  const styles = {
    ...commonVariants,
    book: css`
      font-weight: 400;
      letter-spacing: -0.02em;
    `,
  }[variant];

  return (
    <Heading
      lineHeight={1.2}
      css={css`
        ${styles}
        ${commonStyles(props)}
      `}
      as={type}
      fontSize={fontSizes[type]}
      color={colors[type]}
      {...props}
    />
  );
};

export const H1 = ({ type = 'h1', ...props }) => {
  return <HeadingBase type={type} {...props} />;
};

export const H2 = ({ type = 'h2', ...props }) => {
  return <HeadingBase type={type} {...props} />;
};

export const H3 = ({ type = 'h3', ...props }) => {
  return <HeadingBase type={type} {...props} />;
};

export const StandFirst = props => {
  return (
    <RebassText
      lineHeight={1.2}
      fontSize={{
        xs: 9,
        tabletP: 11,
      }}
      {...props}
    />
  );
};

export const Subheading = ({ variant = 'bold', ...props }) => {
  const { theme } = useThemeUI();
  return (
    <RebassText
      color={theme.colors.heading}
      css={commonVariants[variant]}
      lineHeight={1.3}
      fontSize={4}
      {...props}
    />
  );
};

export const PBase = ({ variant, ...props }) => {
  const { theme } = useThemeUI();

  const variantStyles = {
    ...commonVariants,
    medium: css`
      font-weight: 500;
    `,
  }[variant];

  return (
    <RebassText
      lineHeight={1.3}
      color={theme.colors.heading}
      css={css`
        ${variantStyles}
        ${commonStyles(props)}
      `}
      {...props}
    ></RebassText>
  );
};

export const P1 = props => <PBase fontSize={4} {...props} />;

export const P2 = props => <PBase fontSize={3} {...props} />;

export const P3 = props => <PBase fontSize={2} {...props} />;

export const Caption = ({ variant, ...props }) => {
  const { theme } = useThemeUI();
  const variantStyles = {
    ...commonVariants,
    medium: css`
      font-weight: 500;
    `,
  }[variant];

  return (
    <RebassText
      css={css`
        ${variantStyles}
        ${commonStyles(props)}
      `}
      fontSize={1}
      color={theme.colors.caption}
      lineHeight={1.3}
      {...props}
    />
  );
};
